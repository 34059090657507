import React from "react"
import { graphql, navigate } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import logo from "../components/Icons/backgroundLogo.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import selectSocialIcon from "../components/Icons/selectSocialIcon"
import ReactMarkdown from 'react-markdown'
import IconClosePerson from "../components/Icons/IconClosePerson"

export default function PersonPage({ data, pageContext }) {
  const { title, slug } = pageContext

  const {
    name,
    pronouns,
    metadata,
    location,
    contentTag,
    socialMedia,
    jobPosition,
    profileImage,
    introduction,
  } = data.person.nodes[0]

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;

  const img = getImage(profileImage)

  const handleClick = (e) => {
    contentTag === 'Board of Directors' ?
      navigate('/about/board')
    : 
      navigate('/about/team')
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />

        <div className="person-modal">
          <img src={logo} className="bg-logo" />
          <IconClosePerson handleClick={handleClick}/>

          <div className="person-content">
            <div className="person-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <GatsbyImage image={img} alt={name} />
                    <ul>
                      {socialMedia[0]?.socialMediaItem?.map(media => (
                        <li>
                          <a
                            key={media.socialMediaLink}
                            href={media.socialMediaLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {selectSocialIcon(media.socialMediaId, "white")}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="col-lg-5">
                    <h2>{name}</h2>
                    <h6>{pronouns}</h6>
                    <h6>{jobPosition}</h6>
                    <h6>{location}</h6>
                    <ReactMarkdown children={introduction} allowDangerousHtml />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    person: allDatoCmsStaff(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        introduction
        jobPosition
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        profileImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
        location
        contentTag
        pronouns
        socialMedia {
          socialMediaItem {
            socialMediaId
            socialMediaLink
          }
        }
      }
    }
  }
`
